.main-div {
  background-color: #222732;
  min-height: 100vh;
  display: flex;
  padding: 12px;
}

.main-component {
  flex-basis: 87%;
  background-color: #f8f3ea;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
  flex-wrap: wrap;
}

.account-ballance-box {
  width: 250px;
  height: 150px;
  border-radius: 20px;
  background-color: #c6d0bc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.box-heading {
  display: inline-block;
  padding-left: 5px;
  font-size: 17px !important;
  font-weight: bold !important;
}

.total-view-box {
  width: 250px;
  height: 150px;
  border-radius: 20px;
  background-color: #f6d78b;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.todays-task-box {
  width: 170px;
  height: 160px;
  background-color: #a8a4c88a;
  display: flex;
  border-radius: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.box-heading-div {
  text-align: left;
  margin: 11px 16px 0px 16px;
  width: 100%;
}

.sign {
  background-color: #00000040;
  border-radius: 5px;
  font-weight: bold;
  padding: 0px 6px;
}

.value-of-box {
  font-size: 20px !important;
  font-weight: bolder !important;
}

.progress-bar-value {
  font-size: 15px !important;
  font-weight: bolder !important;
}

.youtube-box {
  width: 1089px;
}

.iframe-container {
  width: 100%;
  border-radius: 20px;
  min-height: 500px;
}

.iframe-container > div {
  width: 100% !important;
  border-radius: 20px;
  min-height: 500px !important;
}

.watch-button {
  margin-top: 20px !important;
  background-color: #4b4d53a8;
}

@media only screen and (max-width: 1400px) {
  .youtube-box {
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .iframe-container {
    min-height: 300px;
  }
  .iframe-container > div {
    min-height: 300px;
  }
}
