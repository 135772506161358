.sign {
  background-color: #00000040;
  border-radius: 5px;
  font-weight: bold;
  padding: 0px 6px;
}

.box-heading {
  display: inline-block;
  padding-left: 5px;
  font-size: 17px !important;
  font-weight: bold !important;
}

.price-title {
  display: block;
  padding-top: 10px;
}
.container {
  flex-basis: 87%;
  background-color: #f8f3ea;
  border-radius: 15px;
  padding-top: 50px;
}

.card-box {
  width: 330px;
  height: 206px;
  background-image: url("../../assets/card-2.png");
  background-size: cover;
  text-align: start;
  border-radius: 30px;
  padding: 20px;
}

.card-box h4 {
  margin-top: 95px;
}

.total-erning1 {
  width: 350px;
  height: 100px;
  background-color: #303747;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.earnings-text1 {
  width: 65%;
  color: white;
  text-align: left;
  margin: 0 14px;
}
.earning-value1,
.earning-label1 {
  font-family: oswald !important;
}
.earning-label1 {
  color: #ffffffcc;
  font-size: 13px;
}
.custom-bg {
  background-color: rgba(48, 55, 71, 0.1) !important;
}
.transactions-box {
  background-color: rgba(48, 55, 71, 0.1) !important;
  max-height: 839px;
}
.transaction-box {
  background-color: #ffffff;
  padding: 15px 25px;
  border-radius: 15px;
}
.all-payments {
  height: calc(100% - 70px);
  overflow: auto;
}

@media only screen and (max-width: 1390px) {
  .total-erning1 {
    width: 300px;
  }
  .earnings-text1 {
    width: 58%;
  }
}

@media only screen and (max-width: 1220px) {
  .total-erning1 {
    width: 280px;
  }
}

@media only screen and (max-width: 600px) {
  .transactions-box {
    max-height: calc(100dvh - 485px);
  }
}
