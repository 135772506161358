.sign {
  background-color: #00000040;
  border-radius: 5px;
  font-weight: bold;
  padding: 0px 6px;
}

.box-heading {
  display: inline-block;
  padding-left: 5px;
  font-size: 17px !important;
  font-weight: bold !important;
}

.user-card {
  border-radius: 10px;
  padding: 30px 5px;
  height: fit-content;
  width: 30%;
}

.price-title {
  display: block;
  padding-top: 10px;
}
.container {
  flex-basis: 87%;
  background-color: #f8f3ea;
  border-radius: 15px;
  padding-top: 50px;
}
.link-box {
  padding: 20px;
  border-radius: 10px;
}
.link-box h3 {
  font-size: 20px;
}
.link-box p {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
}
.link-box .link {
  margin: 10px;
  display: block;
  text-align: start;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 10px;
}
.user-card {
  margin-right: 20px;
}
@media only screen and (max-width: 1390px) {
  .user-card {
    width: 49%;
    margin-right: 0;
  }
  .card-boxs {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 370px) {
  .user-card .box-heading {
    font-size: 14px !important;
  }
  .user-card {
    height: 118px;
    margin-right: 0;
  }
}
