.todays-task-box {
  width: 170px;
  height: 160px;
  background-color: #a8a4c88a;
  display: flex;
  border-radius: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.box-heading-div {
  text-align: left;
  margin: 11px 16px 0px 16px;
  width: 100%;
}

.box-heading {
  display: inline-block;
  padding-left: 5px;
  font-size: 17px !important;
  font-weight: bold !important;
}
.progress-bar-value {
  font-size: 15px !important;
  font-family: oswald !important;
  font-weight: bolder !important;
}

.account-ballance-box {
  width: 250px;
  height: 150px;
  border-radius: 20px;
  background-color: #c6d0bc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sign {
  background-color: #00000040;
  border-radius: 5px;
  font-weight: bold;
  padding: 0px 6px;
}

.value-of-box {
  font-size: 20px !important;
  font-weight: bolder !important;
}

.total-view-box {
  width: 250px;
  height: 150px;
  border-radius: 20px;
  background-color: #f6d78b;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
