.profile-box{
    width: 90%;
}
.box{
    text-align: left;
    border-radius: 20px;
    background-color: #7a7d83;
}
.main-component{
    padding-bottom: 50px;
}
.main-heading{
    font-family: oswald !important;
    color: white;
    font-size: 24px;
    padding: 20px;
    text-align: center;
}
.details-box{
    width: 50%;
    display: flex;
    padding: 5px 10px;
    margin: 20px;
}
.title{
    width: 50%;
    text-align: left;
    font-family: oswald !important;
    color: #000000;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.values{
    width: 50%;
    text-align: left;
    font-family: oswald !important;
    color: #222732;
    font-size: 16px !important;
}
.widraw-btn{
    background-color: #3c4458 !important;
    width: 35%;
    margin: 20px !important; 
    color: #d9b75d !important;
}
.money-png{
    width: 20px !important;
    height: auto !important;
    margin-right: 6px !important;
}